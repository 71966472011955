import TestimonialBase, * as Testimonial from '@segment/matcha/recipes/Testimonial';
import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl';
import { usePageConfig } from '@segment/contexts/page';
import { data } from './data';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var TestimonialTemplate = function TestimonialTemplate(_ref) {
  var _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "base" : _ref$variant,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? variant === "extended" ? "460px" : "auto" : _ref$height,
      overrides = _ref.overrides,
      company = _ref.company,
      color = _ref.color,
      logo = _ref.logo,
      text = _ref.text,
      avatar = _ref.avatar,
      name = _ref.name,
      position = _ref.position,
      extendedRate = _ref.extendedRate,
      extendedRateSymbol = _ref.extendedRateSymbol,
      extendedText = _ref.extendedText,
      extendedLink = _ref.extendedLink;
  var pageConfig = usePageConfig();
  var companyData = data.find(function (item) {
    return item.company === company;
  });
  return _jsxs(TestimonialBase, {
    variant: variant,
    children: [_jsxs(Testimonial.Base, {
      color: color || (companyData === null || companyData === void 0 ? void 0 : companyData.color),
      overrides: overrides,
      height: height,
      children: [_jsxs(Testimonial.BaseContentSlot, {
        children: [_jsx(Testimonial.BaseLogo, {
          children: (companyData === null || companyData === void 0 ? void 0 : companyData.logo) || logo
        }), _jsx(Testimonial.BaseText, {
          children: text || (companyData === null || companyData === void 0 ? void 0 : companyData.text)
        })]
      }), (name || (companyData === null || companyData === void 0 ? void 0 : companyData.name)) && _jsxs(Testimonial.BaseAuthorSlot, {
        children: [(avatar || (companyData === null || companyData === void 0 ? void 0 : companyData.avatar)) && _jsx(Testimonial.AuthorAvatar, {
          alt: "Portrait of" + name,
          src: avatar || String(companyData === null || companyData === void 0 ? void 0 : companyData.avatar)
        }), _jsxs(Testimonial.AuthorInfo, {
          children: [_jsx(Testimonial.AuthorName, {
            children: name || (companyData === null || companyData === void 0 ? void 0 : companyData.name)
          }), _jsx(Testimonial.AuthorPosition, {
            children: position || (companyData === null || companyData === void 0 ? void 0 : companyData.position)
          })]
        })]
      })]
    }), variant === "extended" && _jsxs(Testimonial.Extended, {
      children: [_jsxs(Testimonial.ExtendedTextSlot, {
        children: [(extendedRate || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedRate)) && _jsxs(Testimonial.ExtendedHeader, {
          color: color || (companyData === null || companyData === void 0 ? void 0 : companyData.color),
          children: [extendedRate || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedRate), (extendedRateSymbol || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedRateSymbol)) && _jsx(Testimonial.ExtendedHeaderSup, {
            children: extendedRateSymbol || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedRateSymbol)
          })]
        }), _jsx(Testimonial.ExtendedText, {
          children: extendedText || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedText)
        })]
      }), (extendedLink || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedLink)) && _jsx(Testimonial.ExtendedButtonSlot, {
        children: _jsx(Testimonial.ExtendedStoryLink, {
          children: _jsx(Testimonial.ExtendedButton, {
            colored: Boolean(color),
            href: getAbsoluteUrl(extendedLink || (companyData === null || companyData === void 0 ? void 0 : companyData.extendedLink) || "", pageConfig),
            text: "Read their story"
          })
        })
      })]
    })]
  });
};

export default TestimonialTemplate;