import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

var _templateObject;

import { useEffect, useCallback } from 'react';
import { useElementScroll, useMotionTemplate, useMotionValue, useTransform } from 'framer-motion';
import useResizeObserver from '../useResizeObserver';

/**
 * A hook to create a mask on scroll
 * @param props.wrapper - A ref to the element to observe
 * @param props.axis - The axis to apply the mask
 * @param props.offset - The offset to apply to the mask
 * @returns A string to use as a mask
 */
var useMaskOnScroll = function useMaskOnScroll(_ref) {
  var wrapper = _ref.wrapper,
      _ref$axis = _ref.axis,
      axis = _ref$axis === void 0 ? "horizontal" : _ref$axis,
      _ref$offset = _ref.offset,
      offset = _ref$offset === void 0 ? "8%" : _ref$offset;
  var clientValue = useMotionValue(0);
  var scrollValue = useMotionValue(0);
  var sizeValue = useMotionValue(0);

  var _useElementScroll = useElementScroll(wrapper),
      scrollYProgress = _useElementScroll.scrollYProgress,
      scrollXProgress = _useElementScroll.scrollXProgress;

  var isVertical = axis === "vertical";
  var scrollProgress = isVertical ? scrollYProgress : scrollXProgress;
  var values = /^([-.\d]+(?:\.\d+)?)(.*)$/.exec(offset);
  var degs = isVertical ? useMotionValue(180) : useMotionValue(90);
  var unit = useMotionValue(values && values[2]);
  var offsetValue = values ? Number(values[1]) : 0;

  if (isVertical) {
    var _wrapper$current;

    sizeValue.set(unit.get() === "%" ? 100 : Number((_wrapper$current = wrapper.current) === null || _wrapper$current === void 0 ? void 0 : _wrapper$current.getBoundingClientRect().height));
  } else {
    var _wrapper$current2;

    sizeValue.set(unit.get() === "%" ? 100 : Number((_wrapper$current2 = wrapper.current) === null || _wrapper$current2 === void 0 ? void 0 : _wrapper$current2.getBoundingClientRect().width));
  }

  useEffect(function () {
    if (unit.get() === "%") return;

    var handleResize = function handleResize() {
      if (isVertical) {
        var _wrapper$current3;

        sizeValue.set(Number((_wrapper$current3 = wrapper.current) === null || _wrapper$current3 === void 0 ? void 0 : _wrapper$current3.getBoundingClientRect().height));
      } else {
        var _wrapper$current4;

        sizeValue.set(Number((_wrapper$current4 = wrapper.current) === null || _wrapper$current4 === void 0 ? void 0 : _wrapper$current4.getBoundingClientRect().width));
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return function () {
      return window.removeEventListener("resize", handleResize);
    };
  }, [isVertical, unit]);
  var maskStartSize = useTransform([clientValue, scrollValue, scrollProgress], function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 3),
        clientValue = _ref3[0],
        scrollValue = _ref3[1],
        scrollProgressValue = _ref3[2];

    return clientValue === scrollValue ? 0 : Math.min(offsetValue, scrollProgressValue * sizeValue.get());
  });
  var maskEndSize = useTransform([clientValue, scrollValue, scrollProgress], function (_ref4) {
    var _ref5 = _slicedToArray(_ref4, 3),
        clientValue = _ref5[0],
        scrollValue = _ref5[1],
        scrollProgressValue = _ref5[2];

    return clientValue === scrollValue ? sizeValue.get() : Math.max(sizeValue.get() - offsetValue, scrollProgressValue * sizeValue.get());
  });
  var maskImage = useMotionTemplate(_templateObject || (_templateObject = _taggedTemplateLiteral(["linear-gradient(", "deg, rgba(0,0,0,0) 0", ", rgba(0,0,0,1) ", "", ", rgba(0,0,0,1) ", "", ", rgba(0,0,0,0) ", "", ")"])), degs, unit, maskStartSize, unit, maskEndSize, unit, sizeValue, unit);
  var setValue = useCallback(function () {
    var _ref6, _wrapper$current5, _wrapper$current6, _ref7, _wrapper$current7, _wrapper$current8;

    clientValue.set((_ref6 = isVertical ? (_wrapper$current5 = wrapper.current) === null || _wrapper$current5 === void 0 ? void 0 : _wrapper$current5.clientHeight : (_wrapper$current6 = wrapper.current) === null || _wrapper$current6 === void 0 ? void 0 : _wrapper$current6.clientWidth) !== null && _ref6 !== void 0 ? _ref6 : 0);
    scrollValue.set((_ref7 = isVertical ? (_wrapper$current7 = wrapper.current) === null || _wrapper$current7 === void 0 ? void 0 : _wrapper$current7.scrollHeight : (_wrapper$current8 = wrapper.current) === null || _wrapper$current8 === void 0 ? void 0 : _wrapper$current8.scrollWidth) !== null && _ref7 !== void 0 ? _ref7 : 0);
  }, [clientValue, wrapper, scrollValue]);
  useResizeObserver(wrapper, setValue);
  useEffect(function () {
    return setValue();
  }, [setValue]);
  return maskImage;
};

export default useMaskOnScroll;