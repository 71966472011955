import { useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
/**
 * The useResizeObserver reports changes to the dimensions of an element's content or border box
 * @param ref - A ref to the element to observe
 * @param fn - A callback function called when size of the observed element change
 */

var useResizeObserver = function useResizeObserver(ref, fn) {
  useEffect(function () {
    var resizeObserver = new ResizeObserver(function () {
      fn();
    });

    if (ref !== null && ref !== void 0 && ref.current) {
      resizeObserver.observe(ref.current);
    }

    return function () {
      resizeObserver.disconnect();
    };
  }, [fn, ref]);
};

export default useResizeObserver;