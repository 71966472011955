/**
 * A hook to scroll to an element
 *
 * @param container - A ref to the container to scroll
 * @returns A function to scroll to an element
 */
var useScrollTo = function useScrollTo(container) {
  return function (element) {
    var el = element.current;
    var parent = container.current;

    if (parent && el) {
      parent.scrollTo({
        left: el.offsetLeft - (parent.offsetWidth - el.offsetWidth) / 2,
        behavior: "smooth"
      });
    }
  };
};

export default useScrollTo;