import useEventCallback from '@segment/utils/useEventCallback';
import { setActive, swipedLeft, swipedRight, useSliderContext, useSliderDispatchContext } from './TestimonialSliderContext';

var useSliderState = function useSliderState() {
  var dispatch = useSliderDispatchContext();

  var _useSliderContext = useSliderContext(),
      activeSlide = _useSliderContext.activeSlide,
      slides = _useSliderContext.slides;

  var slideToIndex = useEventCallback(function (i) {
    dispatch === null || dispatch === void 0 ? void 0 : dispatch(setActive({
      activeSlide: i
    }));
  }, []);

  var swipePower = function swipePower(offset, velocity) {
    return Math.abs(offset) * velocity;
  };

  var swipeConfidenceThreshold = 10000;
  var swipe = useEventCallback(function (offset, velocity) {
    if (swipePower(offset, velocity) < -swipeConfidenceThreshold) {
      dispatch === null || dispatch === void 0 ? void 0 : dispatch(swipedRight());
    } else if (swipePower(offset, velocity) > swipeConfidenceThreshold) {
      dispatch === null || dispatch === void 0 ? void 0 : dispatch(swipedLeft());
    }
  }, []);
  return {
    swipe: swipe,
    slideToIndex: slideToIndex,
    slides: slides,
    activeIndex: activeSlide
  };
};

export default useSliderState;