import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import { BonobosMonoLogo, ClearScoreMonoLogo, DigitalOceanMonoLogo, IbmMonoLogo, MeredithMonoLogo } from '@segment/matcha/components/CompanyLogos/CompanyMono';
import { BonobosLogo, ClearScoreLogo, DigitalOceanLogo, IbmLogo, MeredithLogo } from '@segment/matcha/components/CompanyLogos/Company';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var useData = function useData() {
  var appearance = useAppearance();
  var theme = useTheme();
  return useMemo(function () {
    var color;

    switch (appearance) {
      case "light":
        color = theme.palette.brand.darkAcai[700];
        break;

      case "dark":
        color = theme.palette.neutrals.white[100];
        break;

      case "twilioLight":
        color = theme.palette.twilio.gray[70];
        break;

      case "twilioDark":
        color = theme.palette.twilio.gray[70];
        break;

      default:
        color = theme.palette.neutrals.white[100];
    }

    return [{
      company: "ibm",
      logo: _jsx(IbmMonoLogo, {
        color: color
      }),
      logoActive: _jsx(IbmLogo, {}),
      logoName: "IBM"
    }, {
      company: "meredith",
      logo: _jsx(MeredithMonoLogo, {
        color: color
      }),
      logoActive: _jsx(MeredithLogo, {}),
      logoName: "Meredith"
    }, {
      company: "bonobos",
      logo: _jsx(BonobosMonoLogo, {
        color: color
      }),
      logoActive: _jsx(BonobosLogo, {}),
      logoName: "Bonobos"
    }, {
      company: "digital-ocean",
      logo: _jsx(DigitalOceanMonoLogo, {
        color: color
      }),
      logoActive: _jsx(DigitalOceanLogo, {}),
      logoName: "DigitalOcean"
    }, {
      company: "clear-score",
      logo: _jsx(ClearScoreMonoLogo, {
        color: color
      }),
      logoActive: _jsx(ClearScoreLogo, {}),
      logoName: "ClearScore"
    }];
  }, [appearance, theme.palette.brand.darkAcai, theme.palette.neutrals.white, theme.palette.twilio.gray]);
};

export default useData;