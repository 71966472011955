import theme from '@segment/matcha/foundations/Theme';
import { VacasaMonoLogo, FoxMonoLogo, BonobosMonoLogo, IbmMonoLogo, RugsComMonoLogo, DigitalOceanMonoLogo, PagerDutyMonoLogo, DriftMonoLogo, EarnestMonoLogo, ClearScoreMonoLogo, ToggleMonoLogo, StylepitMonoLogo, TradesyMonoLogo, FrameIoMonoLogo, RetoolMonoLogo, MeredithMonoLogo, LookerMonoLogo, TrustpilotMonoLogo, AdvisoMonoLogo, CanduMonoLogo, ProlificInteractiveMonoLogo, YCombinatorMonoLogo, Flowers1800MonoLogo, TypeformMonoLogo, CreativeMarketMonoLogo, ShutterstockMonoLogo, RitualMonoLogo, AllerganMonoLogo, AmbossMonoLogo, ChopraMonoLogo, TheVintageBarMonoLogo, GoodeggsMonoLogo, HalpMonoLogo, CraftJackMonoLogo, DominosMonoLogo, AbInBevMonoLogo, CryptoComMonoLogo, BoxMonoLogo, MongoDbMonoLogo, SanofiMonoLogo, ArduinoMonoLogo, CrossFitMonoLogo, UnivisionMonoLogo, FenderMonoLogo, TradeMeMonoLogo, CampingWorldMonoLogo, AdevintaMonoLogo, VervoeMonoLogo, TillsterMonoLogo, SmartcarMonoLogo, LensOnlineMonoLogo, TheMotleyFoolMonoLogo, VeronicaBeardMonoLogo } from '@segment/matcha/components/CompanyLogos/CompanyMono';
import { SmartcarLogo, LensOnlineLogo } from '@segment/matcha/components/CompanyLogos/Company';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export var data = [{
  company: "box",
  logoName: "Box",
  logo: _jsx(BoxMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CCustomerAI Predictions has equipped Box\u2019s marketing team with the ability to forecast customer behavior to a degree that was simply unavailable to us before. We\u2019ve been able to explore segmenting our audience based on predictive traits like who is most likely to join us at in-person events or who is more likely to purchase, and this allows us to meet those people where they are in their customer journey.\u201D",
  name: "Chris Koehler",
  position: "CMO at Box"
}, {
  company: "vacasa",
  logoName: "Vacasa",
  logo: _jsx(VacasaMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CTwilio and Segment enable Vacasa to understand the entire customer journey to more effectively deliver personalized messages to guests and remove friction from their vacation experience.\u201D",
  name: "G Scott",
  position: "Director of Marketing Analytics",
  extendedRate: "3x",
  extendedText: "more guest bookings with personalized campaigns",
  extendedLink: "/customers/vacasa/"
}, {
  company: "fox",
  logoName: "Fox",
  logo: _jsx(FoxMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "When you use any of our mobile apps, you should be greeted like an old friend. When we deliver an ad to you, it should be relevant. When we suggest content to you, it should be in line with your tastes. This is all thanks to Segment.",
  name: "Daryl Bowden",
  position: "Executive Vice President, Technology Platforms at FOX Corporation",
  extendedRate: "376",
  extendedRateSymbol: "",
  extendedText: "more visitors to FOX Sports \u201CFor You\u201D app"
}, {
  company: "bonobos",
  logoName: "Bonobos",
  logo: _jsx(BonobosMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CAs our business grows, it has become increasingly important to understand how online spend influences offline behavior, which Facebook and Segment have made possible.\u201C",
  name: "Micky Onvural",
  position: "Co-President",
  extendedRate: "3x",
  extendedText: "increase in offline purchases versus control group",
  extendedLink: "/customers/bonobos/"
}, {
  company: "ibm",
  logoName: "IBM",
  logo: _jsx(IbmMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWith real-time integrated data flows from Segment, we can truly understand what people are doing with our platform.\u201D",
  name: "Nic Sauriol",
  avatar: "",
  position: "Software Development Leader",
  extendedRate: "70",
  extendedRateSymbol: "%",
  extendedText: "increase in revenue following a three-month customer messaging pilot program",
  extendedLink: "/customers/ibm/"
}, {
  company: "rugs-com",
  logoName: "Rugs.com",
  logo: _jsx(RugsComMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\"Segment\u2019s first-party audience targeting capabilities directly improved our return on ad spend. With better performing Google and Facebook campaigns...we were able to reinvest part of our optimized ad budgets into other high growth initiatives.\u201D",
  name: "Grayson Bagwell",
  position: "Director of Business Development and E-Commerce",
  extendedRate: "400",
  extendedRateSymbol: "%",
  extendedText: "YoY revenue growth",
  extendedLink: "/customers/rugs-dot-com/"
}, {
  company: "digital-ocean",
  logoName: "DigitalOcean",
  logo: _jsx(DigitalOceanMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSegment allows us to be more precise with how we dynamically suppress or target users in ad campaigns based on actual product usage.\u201D",
  name: "Sam Coren",
  position: "Senior Demand Generation Manager",
  extendedRate: "33",
  extendedRateSymbol: "%",
  extendedText: "improvement in cost per conversion",
  extendedLink: "/customers/digital-ocean/"
}, {
  company: "pager-duty",
  logoName: "PagerDuty",
  logo: _jsx(PagerDutyMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CUsing Pendo and Segment to create this onboarding flow, rather than building it out as part of the core PagerDuty product, saves valuable time for engineering.\u201D",
  name: "Hailey Hickman",
  position: "Program Manager",
  extendedRate: "178",
  extendedRateSymbol: "%",
  extendedText: "increase in mobile app downloads",
  extendedLink: "/customers/pagerduty/"
}, {
  company: "drift",
  logoName: "Drift",
  logo: _jsx(DriftMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWe're using Twilio Engage as our new-age CRM. We identify which prospects are most likely to convert by aggregating individual user intent signals for each account...\u201D",
  name: "Guillaume Cabane",
  position: "Former VP of Growth",
  extendedRateSymbol: "%",
  extendedRate: "150",
  extendedText: "increase in positive conversations in 2 months",
  extendedLink: "/customers/drift/"
}, {
  company: "dominos",
  logoName: "Domino's",
  logo: _jsx(DominosMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "Domino\u2019s turned to Twilio Segment to create a universal view of the customer, better visibility of ad campaign effectiveness, and create hyper personalized audiences with Twilio Engage to increase ROAS, revenue and incremental orders across all paid and owned e-commerce channels.",
  name: "",
  position: "Domino's",
  extendedRateSymbol: "%",
  extendedRate: "65",
  extendedText: "decrease in customer acquisition cost",
  extendedLink: "/customers/dominos/"
}, {
  company: "ab-in-bev",
  logoName: "ABInBev",
  logo: _jsx(AbInBevMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201COur products are vital to our customers\u2019 businesses, and through BEES we\u2019ve empowered them to order the products their consumers love anytime, anywhere. Couple this with real time messaging about when their products will arrive, and what changes have taken place in the process, if any, has deepened our relationship with our customers immensely.\u201D",
  name: "Jason Lambert",
  position: "SVP Product at BEES",
  extendedRateSymbol: "",
  extendedRate: "$2",
  extendedText: "businesses buzzing through a next generation B2B platform with Twilio Segment",
  extendedLink: "/customers/ab-inbev/"
}, {
  company: "crypto-com",
  logoName: "Crypto.com",
  logo: _jsx(CryptoComMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWe knew from day one that Twilio\u2019s solutions were the most qualified because our developers had first-hand experience, knowledge, and success working with them in the past.\u201D",
  name: "Matthew Chan",
  position: "Chief Technology Officer",
  extendedRateSymbol: "%",
  extendedRate: "900",
  extendedText: "forecasted increase in user-base by 2023",
  extendedLink: "/customers/crypto/"
}, {
  company: "earnest",
  logoName: "Earnest",
  logo: _jsx(EarnestMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CTwilio Segment provided Earnest with a single user identity and consistent, granular customer data that the team needed to create the advanced, customized metrics to scale the affiliate program.\u201D",
  name: "Goutham Valeti",
  position: "Head of Analytics",
  extendedRate: "10x",
  extendedText: "loan volume growth and saved borrowers well over $100M",
  extendedLink: "/customers/earnest/"
}, {
  company: "clear-score",
  logoName: "ClearScore",
  logo: _jsx(ClearScoreMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CThe cost of a Segment investment completely beat our in-house options out of the water. It was a no-brainer in terms of cost.\u201D",
  name: "Klaus Thorup",
  avatar: "",
  position: "Chief Technology Officer",
  extendedRate: "3x",
  extendedText: "cost savings with Twilio Segment instead of building in-house",
  extendedLink: "/customers/clearscore/"
}, {
  company: "toggle",
  logoName: "Toggle",
  logo: _jsx(ToggleMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWhat we have in Segment is a platform for serendipity. We\u2019re able to react to many scenarios, innovate quickly, scale resources, and bolster a culture of digital transformation.\u201D",
  name: "Todd Wright",
  position: "Sr. Technologist",
  extendedRateSymbol: "%",
  extendedRate: "64",
  extendedText: "decrease in cost per acquisition",
  extendedLink: "/customers/toggle/"
}, {
  company: "stylepit",
  logoName: "Stylepit",
  logo: _jsx(StylepitMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\"Segment proved to us that our first-party data was more valuable than any third-party data\u2014even third-party tracking cookies\u2014could provide.\"",
  name: "Martin Brummerstedt",
  position: "Data Scientist & Digital Project Manager",
  extendedRate: "1.7x",
  extendedText: "increase in ROAS",
  extendedLink: "/customers/stylepit/"
}, {
  company: "tradesy",
  logoName: "Tradesy",
  logo: _jsx(TradesyMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWe played around and built a couple of prototypes for our own data pipeline, but we quickly realized that it would take three engineers six months to stabilize a platform, and even then it would not be as feature-rich as Segment.\u201D",
  name: "Geoff Apps",
  position: "CTO at Tradesy",
  extendedRate: "6",
  extendedText: "months saved in engineering time",
  extendedLink: "/customers/tradesy/"
}, {
  company: "frame-io",
  logoName: "FrameIo",
  logo: _jsx(FrameIoMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CFrame.io needed a definitive 'single source of truth' where they could unify diverse data sources that would provide the full picture of their customers and Twilio Segment provided that.\u201D",
  name: "Kyle Gesuelli",
  position: "Head of Growth",
  extendedRateSymbol: "%",
  extendedRate: 80,
  extendedText: "reduction in the handle time on support tickets",
  extendedLink: "/customers/frameio/"
}, {
  company: "retool",
  logoName: "Retool",
  logo: _jsx(RetoolMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CAfter we looked at the costs of building the basic infrastructure and the support required, Twilio Segment became a no-brainer and we upgraded from their Startup Program.\u201D",
  name: "Jane Kelly",
  position: "Head of Growth",
  extendedRateSymbol: "",
  extendedRate: "1000+",
  extendedText: "Retool saved over 1,000 engineering hours by using Twilio Segment",
  extendedLink: "/customers/retool/"
}, {
  company: "meredith",
  logoName: "Meredith",
  logo: _jsx(MeredithMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSegment has enabled us to streamline the data capture process while maintaining flexibility to customize per brand as needed.\u201C",
  name: "Grace Preyapongpisan",
  position: "Vice President, Business Intelligence",
  extendedRate: 36,
  extendedText: "brands centralized on the same data analytics infrastructure",
  extendedLink: "/customers/meredith/"
}, {
  company: "candu",
  logoName: "Candu",
  logo: _jsx(CanduMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CThe developer experience with Segment was super easy. Docs were robust enough for us to follow and build an integration within a day.\u201C",
  name: "Michele Riccardo Esposito",
  position: "Chief Technology Officer"
}, {
  company: "looker",
  logoName: "Looker",
  logo: _jsx(LookerMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CHaving a consistent pipeline of clean and standardized first-party data from Segment is an extremely valuable and core data set for our customers\u2019 success.\u201C",
  name: "Keenan Rice",
  position: "VP Strategic Alliances"
}, {
  company: "trustpilot",
  logoName: "Trustpilot",
  logo: _jsx(TrustpilotMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSegment\u2019s Privacy Portal gives us the confidence to align our event data streams with our company\u2019s data privacy and governance standards. With real-time visibility across our event streams, we are able to proactively respect our customers\u2019 privacy.\u201C",
  name: "Rudy Martin",
  position: "Chief Information Security Office"
}, {
  company: "adviso",
  logoName: "Adviso",
  logo: _jsx(AdvisoMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWorking with Segment allows us to better serve our clients by delivering deeper insights, more quickly. Segment reduces time spent building data pipelines and allows us to provide access to reliable, clean, and actionable data to our customers.\u201C",
  name: "Louis-Philippe Mathieu",
  position: "Data Scientist"
}, {
  company: "prolific-interactive",
  logoName: "ProlificInteractive",
  logo: _jsx(ProlificInteractiveMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSegment understands the value of having their end-users be experts on the platform, and that remains a top priority for them. Instead of simply doing it for you, they take the time to guide you to a deeper level of understanding of the nuances of their platform.\u201C",
  name: "David Reyneke",
  position: "Sr. Growth Manager"
}, {
  company: "y-combinator",
  logoName: "YCombinator",
  logo: _jsx(YCombinatorMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSegment helps businesses set up analytics the way it was meant to be \u2014 quantifying product-market fit, understanding customers, and trying new tools \u2014 all without redundant or time-consuming work.\u201C",
  name: "Gustaf Alstr\xF6mer",
  position: "Partner at Y Combinator"
}, {
  company: "flowers-1800",
  logoName: "Flowers1800",
  logo: _jsx(Flowers1800MonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWith Twilio Engage, we are able to collect, unify and connect customer data in real time and build meaningful relationships at every touchpoint of the customer journey. We\u2019re accelerating our digital business and data infrastructure by nearly a decade, and by doing so, delighting our customers.\u201C",
  name: "Amit Shah",
  position: "President"
}, {
  company: "shutterstock",
  logoName: "Shutterstock",
  logo: _jsx(ShutterstockMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "Shutterstock partnered with Segment to unlock the personalization power for their email campaigns. Segment\u2019s expertise accelerated the digital media marketplace\u2019s customer experience with contextually relevant messaging and a 30% lift in click rates."
}, {
  company: "typeform",
  logoName: "Typeform",
  logo: _jsx(TypeformMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CUntil we started standardizing our data, people didn\u2019t realize how messy it had become. With Protocols, we can be confident that data quality issues don\u2019t happen anymore.\u201D",
  name: "Colin Furlong",
  position: "Data Operations & Tracking",
  extendedRateSymbol: "%",
  extendedRate: 75,
  extendedText: "Reduction in duplicate or extraneous tracked events",
  extendedLink: "/customers/typeform/"
}, {
  company: "creative-market",
  logoName: "CreativeMarket",
  logo: _jsx(CreativeMarketMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CProtocols provides the context my teams need to effectively use our customer data in campaigns and analyses.\u201D",
  name: "Akshay Singh",
  position: "Former extended & Product Analytst",
  extendedRateSymbol: "%",
  extendedRate: 93,
  extendedText: "Reduction in time to detect data issues",
  extendedLink: "/customers/creative-market/"
}, {
  company: "allergan",
  logoName: "Allergan",
  logo: _jsx(AllerganMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWe lean heavily on Segment today to identify our consumers and their profile traits, what makes them them, and what kind of journey they are on.\u201D",
  name: "Christine Li",
  position: "Director of Marketing Technology",
  extendedRate: "400M+",
  extendedText: "In sales driven in 2021 YTD by direct-to-consumer",
  extendedLink: "/customers/allergan/"
}, {
  company: "amboss",
  logo: _jsx(AmbossMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  logoName: "Amboss",
  text: "\u201C[...]Twilio Segment has been game-changing, helping us to centralize our customer data far more quickly, efficiently and cost effectively than we could have imagined.\u201D",
  name: "Elmar Weber",
  position: "Chief Technical Officer",
  extendedText: "Reduced time to insights from days to minutes",
  extendedLink: "/customers/amboss/"
}, {
  company: "chopra",
  logoName: "Chopra",
  logo: _jsx(ChopraMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CTwilio Segment is significantly cheaper than having a full data team[...]and allowed us to see what our customers are actually doing.\u201D",
  name: "Josh Wexler",
  position: "Chief Product Officer",
  extendedText: "Maximized resources resulting in the building of data-driven products",
  extendedLink: "/customers/chopra/"
}, {
  company: "ritual",
  logoName: "Ritual",
  logo: _jsx(RitualMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWe use Segment as our CDP and the biggest thing it\u2019s given us is flexibility[...]to define what the ideal experience is for our customers.\u201D",
  name: "Laura Brodie",
  position: "Director of Customer Lifecycle and Growth",
  extendedText: "More agile audience development and increased LTV over time",
  extendedLink: "/customers/ritual/"
}, {
  company: "theVintageBar",
  logoName: "TheVintageBar",
  logo: _jsx(TheVintageBarMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSegment has empowered our company to make decisions smarter and faster to give our customers the best experience possible. With constant experimentation, sophisticated customer data models, and journey mapping, we can deliver personalization at scale and across all channels.\u201D",
  name: "Nikolaj Toxv\xE6rd",
  position: "Chief Marketing Officer",
  extendedRate: "6x",
  extendedText: "inventory growth in 6 months with optimized experiences",
  extendedLink: "/customers/the-vintage-bar/"
}, {
  company: "good-eggs",
  logoName: "GoodEggs",
  logo: _jsx(GoodeggsMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\"Segment has proven not only to be simple, fast, and reliable \u2014 but also an accelerant. Engineering can now focus on features and use cases, rather than infrastructure to get our events from the edge to downstream systems.\"",
  name: "Bob Zoller",
  position: "Founding Engineer",
  extendedRate: "0 Hours",
  extendedText: "engineering time spend managing data pipelines",
  extendedLink: "/customers/goodeggs/"
}, {
  company: "halp",
  logoName: "Halp",
  logo: _jsx(HalpMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\"Using Segment, you save a combination of time and money.\"",
  name: "Fletcher Richman",
  position: "CEO and Co-Founder",
  extendedRate: "4x",
  extendedText: "improvement in the number of activated sign-ups",
  extendedLink: "/customers/halp/"
}, {
  company: "craft-jack",
  logoName: "CraftJack",
  logo: _jsx(CraftJackMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\"With Twilio Engage, we are able to do the work of three or four different tools, all in one centralized platform. We are able to bring transactional conversation and personalized marketing messages all into Twilio Engage for greater efficiency.\"",
  name: "Jordan Dietch",
  position: "VP of Product Management, CraftJack",
  extendedText: "31% increase in new user signups YoY and 80% increase in engineering implementation efficiency",
  extendedLink: "/customers/craftjack/"
}, {
  company: "mongodb",
  logoName: "mongodb",
  logo: _jsx(MongoDbMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CSince implementing Segment we have seen a significant increase in revenue which we attribute to the improved targeting and personalization of our communications.\u201D"
}, {
  company: "sanofi",
  logoName: "sanofi",
  logo: _jsx(SanofiMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CThe process before Segment was much more cumbersome and difficult to explain to marketers. Now, Segment allows us to compile data all in one place, forming a Golden Profile, and allows for other teams to easily utilize and activate data.\u201D"
}, {
  company: "the-motley-fool",
  logoName: "the-motley-fool",
  logo: _jsx(TheMotleyFoolMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CTwilio Segment is much more of a self-service platform. Whereas before we were limited in how we could message or target prospects and members, we can now build and activate audiences almost immediately.\u201D"
}, {
  company: "univision",
  logoName: "univision",
  logo: _jsx(UnivisionMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CRegardless of scale, Segment provides you the tools and platform to move quickly and efficiently. We couldn\u2019t\n    have done this any other way.\u201C"
}, {
  company: "crossfit",
  logoName: "crossfit",
  logo: _jsx(CrossFitMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CNow, our data and teams are cohesive and connected, we can make data driven decisions and reach our customers\n    effectively.\u201C"
}, {
  company: "arduino",
  logoName: "arduino",
  logo: _jsx(ArduinoMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CA big benefit of Segment is being able to activate our first-party data all in one platform. Our previous\n    tool required more manual effort, and therefore limited our marketing communication options\u201C"
}, {
  company: "fender",
  logoName: "Fender",
  text: "To increase adoption and retention rates, Fender also uses tools from Twilio Segment to optimize the customer\n    sales funnel by streamlining the onboarding process.",
  logo: _jsx(FenderMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  name: "",
  position: "",
  extendedRate: "29%",
  extendedText: "reduction in customer churn rate for paid app",
  extendedLink: "/customers/fender"
}, {
  company: "trademe",
  logoName: "TradeMe",
  logo: _jsx(TradeMeMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CThe marketing team has been making very good use of CustomerAI Predictions, and we are keen on continuing to use it to drive campaigns across digital channels.\u201D"
}, {
  company: "camping-world",
  logoName: "CampingWorld",
  logo: _jsx(CampingWorldMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CTwilio Engage allows Camping World to track events like \u2018RV model viewed\u2019 and \u2018RV added to favorites list\u2019 to create profiles, then utilize this information to craft specific omni-channel messages.\u201D"
}, {
  company: "adevinta",
  logoName: "Adevinta",
  logo: _jsx(AdevintaMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "With Segment at its core, Adevinta will enable cross-organizational collaboration through standardized data leading to relevant, personalized customer touch points across all its channels."
}, {
  company: "vervoe",
  logoName: "Vervoe",
  logo: _jsx(VervoeMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWe rely on Segment pretty heavily to feed all of the platforms to understand where the breakdowns are within the customer journey.\u201D"
}, {
  company: "tillster",
  logoName: "Tillster",
  logo: _jsx(TillsterMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  text: "\u201CWith Segment, we are able to use more personalization and intelligent targeting\u201C"
}, {
  company: "smartcar",
  text: _jsx(_Fragment, {
    children: "\u201CBuilding a user-friendly API isn\u2019t always easy\u2014especially if you want to appeal to your users and their users. Thanks to Twilio Segment, we can focus on both areas in one go, and give our team the chance to measure and evaluate the things they feel are most important.\u201D"
  }),
  name: "Sanketh Katta",
  position: "CTO and co-founder, Smartcar",
  logo: _jsx(SmartcarMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  logoActive: _jsx(SmartcarLogo, {}),
  logoName: "Smartcar",
  extendedRate: "22%",
  extendedText: "improvement in developer application conversions",
  extendedLink: "/customers/smartcar/"
}, {
  company: "lens-online",
  text: _jsx(_Fragment, {
    children: "\u201CWhile our previous tech stack, including a CRM, SAP, Zendesk, etc. supported our early growth, it wasn't enough for our future ambitions. We needed to not just understand but anticipate our customer's needs. This realization led us to Twilio Segment, moving beyond traditional CRMs to a 360-degree view of customers, integrating every customer interaction and preference with a customer data platform.\u201D"
  }),
  name: "Pascal",
  position: "CMO & CDO, LensOnline",
  logo: _jsx(LensOnlineMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  logoActive: _jsx(LensOnlineLogo, {}),
  logoName: "Fender",
  extendedRate: "15%",
  extendedText: "reduction in marketing spend",
  extendedLink: "/customers/lens-online/"
}, {
  company: "veronica-beard",
  text: _jsx(_Fragment, {
    children: "\u201CBased on our research, we know that omnichannel customers are our most valuable customers. Because of this, we build Twilio Engage Audiences of our omnichannel customers and top customers in lifetime revenue, to use as the basis for lookalike audiences in Facebook Ads.\u201D"
  }),
  name: "Maxime Lagresle",
  position: "Ecommerce Analytics Manager",
  logo: _jsx(VeronicaBeardMonoLogo, {
    color: theme.palette.neutrals.white[100]
  }),
  logoName: "VeronicaBeard",
  extendedRate: "20% ",
  extendedText: "Reduction in customer acquisition costs (CAC) from Facebook Ads",
  extendedLink: "/customers/veronica-beard/"
}];